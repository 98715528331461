import Vue from 'vue';
import Router from 'vue-router';
import { routes,permissRouter } from './routes';
Vue.use( Router );

const originalPush = Router.prototype.push
Router.prototype.push = function push(location){
    return originalPush.call(this, location).catch(err => err)
}

const router = new Router({
    mode: 'history',
    // 切换路径模式，变成history模式，也就是去掉#
    scrollBehavior: ()=>( {y:0 } ),
    // 滚动条滚动行为，不加这个默认就会记忆原来滚动条的位置
    routes
})

let flag = 0
router.beforeEach((to, from, next) => {
  // let user = JSON.parse(localStorage.getItem('user'));
  let user = JSON.parse(localStorage.getItem('user')) || '';
  let menu = JSON.parse(localStorage.getItem('menu')) || '';
  let host = document.domain.split('.').slice(-2).join('.');
  if(to.path !== '/login' && user){
    if(flag == 0){
        // 过滤数据
        var newRouter = [];
        newRouter.push(permissRouter[0])
        menu.forEach((item,i) =>{
          permissRouter.some(item1 => {
            if(item1.alias == item){
              var arr = Vue.prototype.deepCopy(item1);
              arr.children = [];
              newRouter.push(arr)
              return true
            }else if(item1.children && item1.children.length > 0){
              item1.children.some(item2 => {
                if(item2.alias == item){
                  var str = item.slice(0,(item.indexOf('-')))
                  newRouter.some(item3 => {
                    /notice/.test(item) && console.log(1,item3.alias, str)
                    if(item3.alias == str){
                      item3.children.push(item2)
                      return true
                    }
                  })
                  return true
                }
                // 如果有三级菜单 ，暂无 先放空
              })
            }
          })
        })
        var allRouter = router.options.routes.concat(newRouter);
        console.log('allRouter',allRouter,to);
        router.options.routes = allRouter;
        router.addRoutes(newRouter);
        flag++
        if(to.name=='404'){
            console.log('404',location.href);
        }
        next({ ...to, replace: true })
    }else{
      // console.log(to.path, from.path)
      // 清除店铺账号管理缓存
      if(!(to.path == '/store/account/list' && from.path == '/' || to.path == '/store/data/dataInfo' && from.path == '/store/account/list' || to.path == '/store/account/list' && from.path == '/store/data/dataInfo')){
        localStorage.removeItem('accountListFrome')
        localStorage.removeItem('accountListPage')
      }
      // 清除店铺数据管理缓存
      if(!(to.path == '/store/data/list' && from.path == '/' || to.path == '/store/data/dataModule' && from.path == '/store/data/list' || to.path == '/store/data/list' && from.path == '/store/data/dataModule' || to.path == '/store/data/dataInfo' && from.path == '/store/data/list' || to.path == '/store/data/list' && from.path == '/store/data/dataInfo' || to.path == '/store/data/list' && from.path == '/store/data/permiss' || to.path == '/store/data/permiss' && from.path == '/store/data/list')){
        localStorage.removeItem('dataListFrome')
        localStorage.removeItem('dataListPage')
      }
      next()
    }
  }else if (to.path !== '/login' && !user) {
    next('/login')
  }else{
    localStorage.removeItem('user');
    localStorage.removeItem('isOne');
    localStorage.removeItem('massageCount');
    document.cookie='userName='+''+';path=/;domain='+ host;
    document.cookie='userId='+''+';path=/;domain='+ host;
    document.cookie='token='+''+';path=/;domain='+ host;
    document.cookie='avatar='+''+';path=/;domain='+ host;
    next();
  }
})

export default router;

