/*
* @Author: q461542524
* @Date:   2017-11-13 14:39:31
 * @Last Modified by: wy 461542524@qq.com
 * @Last Modified time: 2019-10-26 11:43:36
*/
// import { Spin } from 'iview';
import axios from 'axios';
import qs from 'qs';
import router from '../router/routes';

// 防止频繁请求
let pending = [];
//获取token
var user = localStorage.getItem('user'),
    dataToken,
    apiVersion = localStorage.getItem('apiVersion');
if (user) {
    user = JSON.parse(user);
    dataToken = user.token
    
}
let CancelToken = axios.CancelToken;
let cancelPending = ( config ) => {
    pending.forEach( ( item, index ) => {
        if ( config ) {
            if ( item.UrlPath === config.url ) {
                item.Cancel(); // 取消请求
                pending.splice( index, 1 ); // 移除当前请求记录
            }
        } else {
            item.Cancel(); // 取消请求
            pending.splice( index, 1 ); // 移除当前请求记录
        }
    } );
};

axios.interceptors.request.use( ( config ) => {
    // cancelPending( config );
    // config.cancelToken = new CancelToken( ( res ) => {
    //     pending.push( { 'UrlPath': config.url, 'Cancel': res } );
    // } );
    // loading
    config.headers.token = dataToken;
    return config;
}, ( error ) => Promise.reject( error ) );

axios.interceptors.response.use( ( response ) => {
    // cancelPending( response.config );
    if ( !dataToken ) {
        router.replace( {
            name: 'login',
        } );
    }
    if ( response.data.msg === '门店不存在' ) {
        localStorage.clear();
        router.replace( { name: 'login' } );
    }
    if ( response.data.code === -9999 ) {
        // 未登录 或登录过期
        localStorage.setItem( 'access_token', '' );
        localStorage.setItem( 'username', '' );
        // 跳转
        router.replace( {
            name: 'login',
        } );
    } else {
        // console.warn('系统返回错误：', response.data)
        return response;
    }
    return response;
}, ( error ) => Promise.resolve( error.response ) );

// eslint-disable-next-line require-jsdoc
function checkStatus ( response ) {
    // Spin.hide();
    // 如果http状态码正常，则直接返回数据
    if ( response && ( response.status === 200 || response.status === 304 || response.status === 400 ) ) {
        return response;
        // 如果不需要除了data之外的数据，可以直接 return response.data
    }
    // 异常状态下，把错误信息返回去
    return {
        status: -404,
        msg: '网络异常',
    };
}

// eslint-disable-next-line require-jsdoc
function checkCode ( res ) {
    // 请求结束
    // 如果code异常(这里已经包括网络错误，服务器错误，后端抛出的错误)，可以弹出一个错误提示，告诉用户
    if ( res.status === -1 ) {
        console.log( '服务器异常,请求失败' );
    }
    return res;
}

export default {
    post ( url, data, contype ) {
        // if ( !data ) {
        //     Spin.show();
        // } else if ( !data.notShowSpin ) {
        //     Spin.show();
        // }
        // 请求开始前
        return axios( {
            method: 'post',
            url,
            data: contype ? data : qs.stringify( data ),
            timeout: 300000,
            headers: {
                'X-Requested-With': 'XMLHttpRequest',
                'Content-Type': contype ? contype : 'application/x-www-form-urlencoded; charset=UTF-8',
                // 'application/json; charset=UTF-8'
            },
        } ).then( ( response ) => checkStatus( response ) )
            .then( ( res ) => checkCode( res ) );
    },
    get ( url, params ) {
        // if ( !params ) {
        //     Spin.show();
        // } else if ( !params.notShowSpin ) {
        //     Spin.show();
        // }
        // Spin.show()
        return axios( {
            method: 'get',
            url,
            params,
            // get 请求时带的参数
            timeout: 20000,
            headers: {
                'X-Requested-With': 'XMLHttpRequest',
            },
        } ).then( ( response ) => checkStatus( response ) )
            .then( ( res ) => checkCode( res ) );
    },
};
