import Vue from 'vue'
import App from './App.vue'
import router from './router/index'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import ViewUI from 'view-design'
import 'view-design/dist/styles/iview.css';
import VueWechatTitle from 'vue-wechat-title';
import store from './vuex/store'
import Vuex from 'vuex'
//粒子特效
import VueParticles from 'vue-particles'
// import env from './api/env'
// Vue.prototype.ENV = env;

//新增接口请求
import api from '@/api/apiTwo';
import http from '@/api/http';
Vue.prototype.API = api;
Vue.prototype.HTTP = http;

import 'iview/dist/styles/iview.css';    // 使用 CSS
//全局使用
Vue.use(VueParticles)
Vue.use(ElementUI)
Vue.use(Vuex)

Vue.use(ViewUI,{
  transfer: true,
  size: 'small',
  capture: false,
  select: {
    arrow: 'md-arrow-dropdown',
    arrowSize: 20
  }
}).use( VueWechatTitle );

// 全局依赖注入
import 'babel-polyfill';

//注入全局变量
// import api from '@/utils/api';
// import request from '@/utils/request';
// import common from '@/utils/common';
// import options from '@/utils/options';

// Vue.prototype.$api = api;
// Vue.prototype.$request = request;
// Vue.prototype.$common = common;
// Vue.prototype.$option = options;

// import status from '@/utils/status';
// Vue.prototype.$status = status;

// 运用bus来代替vuex

// 关闭生产环境提示
Vue.config.productionTip = false;


//取消返回
Vue.prototype.goBack = function(){
  router.go(-1);
}
//字符串去除[]
Vue.prototype.trimArry = function (arr){
  return arr.substr(1, arr.length-2)
}
//深拷贝对象
Vue.prototype.deepCopy = function(source) {
  var sourceCopy = source instanceof Array ? [] : {};
  for (var item in source) {
    sourceCopy[item] = typeof source[item] === 'object' ? this.deepCopy(source[item]) : source[item];
  }
  return sourceCopy;
}

//获取url地址参数--单个
Vue.prototype.GetQueryString = function() {
  let a = location.hash.substr(1), an = a.length;
  let b = a.indexOf('=');
  const c = a.slice(b+1,an);
  return c
}

//日期格式转换
Vue.prototype.format = function(format) {
  var date = {
    "M+": this.getMonth() + 1,
    "d+": this.getDate(),
    "h+": this.getHours(),
    "m+": this.getMinutes(),
    "s+": this.getSeconds(),
    "q+": Math.floor((this.getMonth() + 3) / 3),
    "S+": this.getMilliseconds()
  };
  if (/(y+)/i.test(format)) {
    format = format.replace(RegExp.$1, (this.getFullYear() + '').substr(4 - RegExp.$1.length));
  }
  for (var k in date) {
    if (new RegExp("(" + k + ")").test(format)) {
      format = format.replace(RegExp.$1, RegExp.$1.length == 1
          ? date[k] : ("00" + date[k]).substr(("" + date[k]).length));
    }
  }
  return format;
}

//获取指定月份天数
Vue.prototype.mGetDate = function(year, month){
    var d = new Date(year, month, 0);
    return d.getDate();
}

//不重复添加数据到数组
Vue.prototype.addDataToArry = function(a, b) {
  if(a.indexOf(b) < 0) a.push(b);
}

//查找数组对应值下标
Vue.prototype.findArrIndex = function(arr,value){
  for(var i = 0,vlen = arr.length; i < vlen; i++){
    if(arr[i] == value){
      return i;
    }
  }
}

//html解码
Vue.prototype.htmldecode = function(s){
  var div = document.createElement('div');
  div.innerHTML = s;
  return div.innerText || div.textContent;
}

//过滤特殊字符
Vue.prototype.filterCharacter = function(value) {
  var pattern = new RegExp("[`~!@#$^&*()=|{}':;',\\[\\].<>/?~！@#￥……&*（）——|{}【】‘；：”“'。，、？]")
  var rs = "";
  for (var i = 0; i < value.length; i++) {
    rs = rs+value.substr(i, 1).replace(pattern, '');
  }
  return rs;
}

//限制上传图片格式
Vue.prototype.isImage = function(file) {
  const isImg = file.type === 'image/jpg' || file.type === 'image/png' || file.type === 'image/gif' || file.type === 'image/jpeg' || file.type === 'image/bmp';
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isImg) {
    this.$message.error('上传文件格式不正确,无法上传');
  }else if(!isLt2M){
    this.$message.error('上传文件大小不能超过 2MB!');
  }
  return isImg && isLt2M;
}

//只允许上传execl
Vue.prototype.isExecl = function(file) {
    let xls = file.name.split('.');
    const isExecl = file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || file.type === 'application/vnd.ms-excel' || xls[xls.length-1] === 'xls' || xls[xls.length-1] === 'csv';

    if (!isExecl) {
        this.$message.error('请选择execl文件格式');
    }
    return isExecl
}

//换转特殊符号为htmlcode;
Vue.prototype.isSymbol = function(val) {
  let isSymbol;
  isSymbol = val.replace(/\s+([^<>]+)(?=<)/g, function (match) { return match.replace(/ /g, '&nbsp;'); });
  return isSymbol
}

//富文本编辑器不能为空
Vue.prototype.editorHasCont = function(val) {
    let a, b, v = true;
    a = val.substring(3)
    b = a.slice(0, -4)
    b = b.replace(/ /g, '')
    if (b == '') v = false
    return v
}

// 验证输入的网址是否正确
Vue.prototype.isUrl = function checkUrl(urlString){
  let v = true
  var reg=/(http|ftp|https):\/\/[\w\-_]+(\.[\w\-_]+)+([\w\-\.,@?^=%&:/~\+#]*[\w\-\@?^=%&/~\+#])?/;
  if(!reg.test(urlString)) v = false
  return v
}

//tag标签不能添加‘,’
Vue.prototype.isHasComma = function(val) {
    let comma = true;
    if (val.indexOf(',') > -1) {
        this.$notify({
            title: '提示',
            message: '添加的内容不能带有逗号‘,’',
            type: 'error'
        });
        comma = false
    }
    return comma
}

//对字符串扩展--多个空格缩减为1个空格
String.prototype.ResetBlank=function(val){
    var regEx = /\s+/g;
    return val.replace(regEx, ' ');
};

//两个数组合并去重
Vue.prototype.delMore=function(arr1,arr2){
    //不要直接使用var arr = arr1，这样arr只是arr1的一个引用，两者的修改会互相影响
    var arr = arr1.concat();
    //或者使用slice()复制，var arr = arr1.slice(0)
    for(var i=0;i<arr2.length;i++){
        arr.indexOf(arr2[i]) === -1 ? arr.push(arr2[i]) : 0;
    }
    return arr;
};

//情况数据
Vue.prototype.clearData = function(obj){
  for(const item in obj){
    if(obj[item] instanceof Array){
      obj[item] = []
    } else if(obj[item] instanceof Object){
      this.clearData(obj[item])
    } else {
      obj[item] = ''
    }
  }
  return obj
}

Vue.prototype.pickerOptions = {
  shortcuts: [{
    text: '最近一周',
    onClick(picker) {
      const end = new Date();
      const start = new Date();
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
      picker.$emit('pick', [start, end]);
    }
  }, {
    text: '最近一个月',
    onClick(picker) {
      const end = new Date();
      const start = new Date();
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
      picker.$emit('pick', [start, end]);
    }
  }, {
    text: '最近三个月',
    onClick(picker) {
      const end = new Date();
      const start = new Date();
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
      picker.$emit('pick', [start, end]);
    }
  }]
};
Vue.prototype.timePlug = {
  type: "yyyy-MM-dd" ,
  scope: "['00:00:00', '23:59:59']"
} 

new Vue({
  render: h => h(App),
  router,
  store,
}).$mount('#app')
