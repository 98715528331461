//公共部分
const Login = () => import('../views/login.vue') //登录
const NotFound = () => import('../views/404.vue') //404错误页
const Home = () => import('../views/home.vue') //主概况

//系统管理
const pmsAdmin = () => import('../views/permission/admin.vue') //管理员管理列表
const pmsAdminAdd = () => import('../views/permission/adminAdd.vue') //管理员管理--新增、编辑
const pmsAdminRole = () => import('../views/permission/adminRole.vue') //角色组管理
const pmsAdminRoleAdd = () => import('../views/permission/adminRoleEditor.vue') //角色组管理--新增、编辑
const changePassword = () => import('../views/permission/changePassword.vue') //修改密码


export const routes = [{
    path: '/login',
    component: Login,
    name: 'login',
    hidden: true
},
{
    path: '/',
    redirect: {
        path: '/index'
    },
    hidden: true
},
{
    path: '/404',
    component: NotFound,
    name: '404',
    hidden: true
},
{
    path: '/index',
    component: Home,
    name: 'main',
    hidden: true,
    children: [{
        path: '/main',
        name: '首页',
        component: () => import('@/views/Main'),
    },
    {
        path: 'changePassword',
        component: changePassword,
        name: '修改密码',
        hidden: true,
    },]
},
];

export const permissRouter = [
    {
        path: '*',
        hidden: true,
        component: NotFound,
        redirect: {
            path: '/404'
        }
    },
    {
        path: '/',
        component: Home,
        name: '系统管理',
        alias: 'permission',
        iconCls: 'el-icon-setting',
        children: [
            {
                path: '/permission/admin',
                component: () => import('@/views/permission/admin'),
                name: '系统账号管理',
                alias: 'permission-admin',
                redirect: {
                    name: '系统账号列表',
                },
                children: [
                    {
                        path: 'adminList',
                        component: () => import('@/views/permission/adminList'),
                        name: '系统账号列表',
                    },
                    {
                        path: 'adminAdd',
                        component: () => import('@/views/permission/adminAdd'),
                        name: '编辑系统账号',
                        hidden: true,
                    },
                ]
            },
            {
                path: '/permission/adminRole',
                component: () => import('@/views/permission/admin'),
                name: '系统角色管理',
                alias: 'permission-adminRole',
                redirect: {
                    name: '系统角色列表',
                },
                children: [
                    {
                        path: 'adminRoleList',
                        component: () => import('@/views/permission/adminRoleList'),
                        name: '系统角色列表',
                    },
                    {
                        path: 'adminRoleEditor',
                        component: () => import('@/views/permission/adminRoleEditor'),
                        name: '编辑系统角色',
                        hidden: true,
                    },
                ]
            },
        ]
    },
    {
        path: '/store',
        component: Home,
        name: '店铺管理',
        iconCls: 'el-icon-monitor',
        alias: 'store',
        children: [
            {
                path: '/store/account',
                name: '店铺账号管理',
                component: () => import('@/views/store/account'),
                alias: 'store-account',
                redirect: {
                    name: '账号列表'
                },
                children: [
                    {
                        path: 'list',
                        name: '账号列表',
                        component: () => import('@/views/store/accountList'),
                        hidden: true,
                    },
                    {
                        path: 'accountEditor',
                        component: () => import('@/views/store/accountEditor'),
                        name: '创建店铺账号',
                        hidden: true,
                    },
                ]
            },
            {
                path: '/store/domainName',
                name: '店铺域名管理',
                redirect: {
                    name: '等待注册'
                },
                alias: 'store-domainName',
                component: () => import('@/views/store/domainName'),
                children: [{
                    path: '/store/domainName/domainNamerRegiser',
                    name: '等待注册',
                    component: () => import('@/views/store/domainNamerRegiser'),
                    hidden: true
                },
                {
                    path: '/store/domainName/domainNamerRealname',
                    name: '等待解析',
                    component: () => import('@/views/store/domainNamerRealname'),
                    hidden: true
                },
                {
                    path: '/store/domainName/domainNamerParsing',
                    name: '解析成功',
                    component: () => import('@/views/store/domainNamerParsing'),
                    hidden: true
                },
                {
                    path: '/store/domainName/domainNamerSuccess',
                    name: '开通成功',
                    component: () => import('@/views/store/domainNamerSuccess'),
                    hidden: true
                },
                {
                    path: '/store/domainName/domainNamerRenewal',
                    name: '续费提醒',
                    component: () => import('@/views/store/domainNamerRenewal'),
                    hidden: true
                },
                ]
            },
            {
                path: '/store/account/appUpload',
                component: () => import('@/views/store/appUpload'),
                name: '创建app上传',
                alias: 'store-appUpload',
            },
            {
                path: '/store/data',
                name: '店铺数据管理',
                component: () => import('@/views/store/account'),
                alias: 'store-data',
                redirect: {
                    name: '店铺账号列表'
                },
                children: [
                    {
                        path: 'list',
                        name: '店铺账号列表',
                        component: () => import('@/views/store/data'),
                        hidden: true,
                    },
                    {
                        path: 'dataInfo',
                        component: () => import('@/views/store/dataInfo'),
                        name: '店铺详细信息',
                        hidden: true,
                    },
                    {
                        path: 'dataModule',
                        component: () => import('@/views/store/dataModule'),
                        name: '模块定制',
                        hidden: true,
                    },
                    {
                        path: 'dataLog',
                        component: () => import('@/views/store/dataLog'),
                        name: '店铺操作日志',
                        hidden: true,
                    },
                    {
                        path: 'dataPayLog',
                        component: () => import('@/views/store/dataPayLog'),
                        name: '店铺付费日志',
                        hidden: true,
                    },
                ]
            },
            {
                path: '/store/data/permissList',
                component: () => import('@/views/store/permissList'),
                name: '店铺权限设置',
                redirect: {
                    name: '店铺权限',
                },
                hidden: true,
                alias: 'store-permissList',
                children: [
                    {
                        path: '/store/data/permiss',
                        component: () => import('@/views/store/permiss'),
                        name: '店铺权限',
                        hidden: true,
                    },
                    {
                        path: '/store/data/permissAccept.vue',
                        component: () => import('@/views/store/permissAccept.vue'),
                        name: '接受合作方案',
                        hidden: true,
                    },
                    {
                        path: '/store/data/permissProvide.vue',
                        component: () => import('@/views/store/permissProvide.vue'),
                        name: '提供合作方案',
                        hidden: true,
                    },
                ]
            },
        ]
    },
    {
        path: '/intoManagement',
        component: Home,
        name: '支付管理',
        iconCls: 'el-icon-user',
        alias: 'intoManagement',
        children: [
            {
                path: '/intoManagementInto',
                component: () => import('@/views/store/account'),
                name: '收阿收进件',
                redirect: {
                    name: '进件管理',
                },
                alias: 'intoManagement-into',
                children: [
                    {
                        path: 'list',
                        name: '进件管理',
                        component: () => import('@/views/intoManagement/list'),
                    },
                    {
                        path: 'add',
                        name: '增加进件',
                        component: () => import('@/views/intoManagement/add'),
                    }
                ]
            },
            {
                path: '/intoManagement/store',
                component: () => import('@/views/intoManagement/store'),
                name: '商户管理',
                alias: 'intoManagement-search',
            },
            {
                path: '/intoManagement/channelWater',
                component: () => import('@/views/intoManagement/channelWater'),
                name: '渠道流水',
                alias: 'intoManagement-channelWater',
            },
            // {
            //     path: '/intoManagement/search',
            //     component: () => import('@/views/intoManagement/search'),
            //     name: '商户查询',
            //     alias: 'intoManagement-search',
            // },
            // {
            //     path: '/intoManagement/storeList',
            //     component: () => import('@/views/intoManagement/storeList'),
            //     name: '商户列表',
            //     redirect: {
            //         name: '商户列表内容',
            //     },
            //     alias: 'intoManagement-storeList',
            //     children: [
            //         {
            //             path: 'list',
            //             component: () => import('@/views/intoManagement/storeList'),
            //             name: '商户列表内容',
            //         },
            //         {
            //             path: 'upTemplate',
            //             component: () => import('@/views/intoManagement/upTemplate'),
            //             name: '添加商户模板',
            //         }
            //     ]
            // },
            // {
            //     path: '/intoManagement/rate',
            //     component: () => import('@/views/intoManagement/rate'),
            //     name: '费率切换商户',
            //     alias: 'intoManagement-rate',
            // },
        ]
    },
    {
        path: '/order',
        component: Home,
        name: '订单管理',
        iconCls: 'el-icon-document-copy',
        alias: 'order',
        children: [
            {
                path: '/order/shop',
                component: () => import('@/views/store/account'),
                name: '店铺订单管理',
                redirect: {
                    name: '订单列表',
                },
                alias: 'order-shop',
                children: [
                    {
                        path: 'list',
                        name: '订单列表',
                        component: () => import('@/views/order/shop'),
                    },
                    {
                        path: 'createdOrder',
                        name: '创建订单',
                        component: () => import('@/views/order/createdOrder'),
                    }
                ]
            }
        ]
    },
    {
        path: '/app',
        component: Home,
        name: 'APP管理',
        iconCls: 'el-icon-mobile',
        alias: 'app',
        children: [
            {
                path: '/app/c',
                name: 'C端App管理',
                component: () => import('@/views/app/userAppMan'),
                alias: 'app-c',
            },
            {
                path: '/app/b',
                name: 'B端App管理',
                component: () => import('@/views/app/storeAppMan'),
                alias: 'app-b',
            }
        ]
    },
    {
        path: '/data',
        component: Home,
        name: '数据',
        iconCls: 'el-icon-cpu',
        alias: 'data',
        children: [{
            path: '/data/soccerLottery',
            component: () => import('@/views/data/soccerLottery'),
            name: '竞彩足球',
            alias: 'data-soccerLottery',
        },
        {
            path: '/data/football',
            component: () => import('@/views/data/football'),
            name: '14场和任九',
            alias: 'data-football',
        },
        {
            path: '/data/beijingSingle',
            component: () => import('@/views/data/beijingSingle'),
            name: '北京单场',
            alias: 'data-beijingSingle',
        },
        {
            path: '/data/number',
            component: () => import('@/views/data/number'),
            name: '数字彩',
            alias: 'data-number',
        },
        {
            path: '/data/basketball',
            component: () => import('@/views/data/basketball'),
            name: '竞彩篮球',
            alias: 'data-basketball',
        }
        ]
    },
    {
        path: '/operate',
        component: Home,
        name: '日常运营',
        iconCls: 'el-icon-finished',
        alias: 'operate',
        children: [{
            path: '/operate/notice',
            component: () => import('@/views/store/account'),
            name: '通知管理',
            alias: 'operate-notice',
            redirect: {
                name: '通知列表'
            },
            children: [
                {
                    path: 'list',
                    component: () => import('@/views/operate/notice/list'),
                    name: '通知列表',
                },
                {
                    path: 'push',
                    component: () => import('@/views/operate/notice/push'),
                    name: '通知发送',
                }
            ]
        },{
            path: '/operate/sms',
            component: () => import('@/views/operate/sms'),
            name: '短信列表',
            alias: 'operate-sms',
        },
        {
            path: '/operate/banner',
            component: () => import('@/views/store/account'),
            name: 'banner管理',
            alias: 'operate-banner',
            redirect: {
                name: 'banner列表'
            },
            children: [
                {
                    path: 'bannerList',
                    component: () => import('@/views/operate/bannerList'),
                    name: 'banner列表',
                },
                {
                    path: 'bannerAdd',
                    component: () => import('@/views/operate/bannerAdd'),
                    name: '添加banner',
                }
            ]
        },
        {
            path: '/operate/plan',
            component: () => import('@/views/store/account'),
            name: '方案管理',
            alias: 'operate-planList',
            redirect: {
                name: '方案列表'
            },
            children: [
                {
                    path: 'planList',
                    component: () => import('@/views/operate/planList'),
                    name: '方案列表',
                },
                {
                    path: 'planDetail',
                    component: () => import('@/views/operate/planDetails/index'),
                    name: '方案详情',
                }
            ]
        },
        // {
        //     // C端app管理
        //     path: '/operate/userAppMan',
        //     component: () => import('@/views/operate/userAppMan'),
        //     name: 'C端APP管理',
        //     alias: 'operate-userApp',
        // },{
        //     path: '/operate/storeAppMan',
        //     component: () => import('@/views/operate/storeAppMan'),
        //     name: '店主APP管理',
        //     alias: 'operate-storeApp',
        // },
        {
            path: '/operate/printer',
            component: () => import('@/views/operate/printer'),
            name: '打印机订单',
            alias: 'operate-printer',
        },{
            path: '/operate/tousu',
            component: () => import('@/views/operate/tousu'),
            name: '投诉管理',
            alias: 'operate-tousu',
        },{
            path: '/operate/chuanpiao',
            component: () => import('@/views/operate/chuanpiao'),
            name: '截止后传票管理',
            alias: 'operate-chuanpiao',
        },{
            path: '/operate/chuanpiaoList',
            component: () => import('@/views/operate/chuanpiaoList'),
            name: '传票列表',
            alias: 'operate-chuanpiaoList',
            hidden: true,
        },
        ]
    },
    {
        path: '/finance',
        component: Home,
        name: '财务报表',
        iconCls: 'el-icon-s-finance',
        alias: 'finance',
        children: [
            {
                path: '/finance/moon',
                name: '月报表',
                component: () => import('@/views/finance/moon'),
                alias: 'finance-moon',
            },
            {
                path: '/finance/sun',
                name: '日报表',
                component: () => import('@/views/finance/sun'),
                alias: 'finance-sun',
            }
        ]
    },
    {
        path: '/identifier',
        component: Home,
        name: '标识管理',
        iconCls: 'el-icon-s-help',
        alias: 'identifier',
        children: [
            {
                path: '/identifier/identifier',
                name: '标识列表',
                component: () => import('@/views/identifier/index'),
                alias: 'identifier-identifier',
            }
        ]
    },
]
export default routes;