<template>
  <div id="app">
    <transition :name="transitionName" mode="out-in">
      <!-- <router-view v-wechat-title="$route.meta.title" class="child-view"/> -->
      <router-view v-wechat-title="$route.meta.title"/>
    </transition>
  </div>
</template>

<script>
export default{
  name: 'APP',
  data () {
      return {
          transitionName: '',
      };
  },
};
</script>

<style lang="less">
@import "./assets/css/base.less";
#app {
  width: 100%;
  height: 100%;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
<style lang="scss">
@import 'styles/app.scss';
</style>
