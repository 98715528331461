/*
 * @Author: qq461542524
 * @Date:   2017-11-13 14:44:42
 * @Last Modified by: wy  757513343@qq.com
 * @Last Modified time: 2020-02-25 15:31:01
*/
let apiUrl = process.env.VUE_APP_ROOT;

import { Message } from 'element-ui';
export default {
    alertMsg ( code, msg ) {
        if ( code == this.successCode1 || code == this.successCode2 ) {
            Message.success( msg || '提交成功' );
        } else {
            Message.error( msg || '提交失败' );
        }
    },
    alertMsg2 ( code, msg ) {
        if ( code == this.successCode1 || code == this.successCode2 ) {
            Modal.success( {
                title: '提示',
                content: msg || '提交成功！',
            } );
        } else {
            Modal.error( {
                title: '提示',
                content: msg || '提交失败！',
            } );
        }
    },
    successCode1: 200,
    successCode2: 1,
    defaultPrintNum: '212495',
    // 绑定店铺
    bindMerchant: `${apiUrl}/store/rate/bindMerchant`,
    sendRateChangeNotice: `${apiUrl}/store/rate/sendRateChangeNotice`,
    //开通关闭开启商户
    confirmAction: `${apiUrl}/store/rate/confirmAction`,
    //excel商户上传
    templateUpload: `${apiUrl}/store/rate/templateUpload`,
    //通道接口
    CHANNELLIST: `${apiUrl}/store/rate/channelList`,
};
